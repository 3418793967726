<template>
  <v-app>
  
    <!-- Diálogo de progresso -->
  <v-dialog v-model="showProgress" persistent max-width="300">
    <v-card>
      <v-card-title>
        Baixando arquivo...
      </v-card-title>
      <v-card-text>
        <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a {{dias}} dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>



  <v-container style="background-color:#f8f9fb;" >
    <v-col cols="12" >
      <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'mes'"  :title_opcao="'Situação'" :campos="'S'" :produtos="tipos_funerarios"
      @filtrar="filterData" 
        ></filtro-component>
    </v-col>
  </v-container>
    
  
    <v-container
      id="contrato"
      fluid
      tag="section">
      
      <v-row>

      
    
        <v-col
        cols="12"
        sm="6"
        lg="3">
        
         <base-material-stats-card 
           color="rgb(71 130 180)"
           icon="mdi-file-document-check-outline"
           title="Total de Contratos"
           sub-icon-color="blue"
           :valuex='false'
            :value='this.total_contrato'
            :sub-text="'Total Documentos: ' + this.totalRecords"
            />
    
        
    
       </v-col>
    
    
       <v-col
       cols="12"
       sm="6"
       lg="3">
     
      <base-material-stats-card 
        color="rgb(71 130 180)"
        icon="mdi-chart-line"
        title="Previsto"
        sub-icon-color="red"
        :value='formatarMoeda(this.totalEsperado)'
        :valuex='false'
        :sub-text="'Total Previsto'"
      />
     
    
    </v-col>
    
    
      <v-col
       cols="12"
       sm="6"
       lg="3">
       
        <base-material-stats-card 
          color="rgb(71 130 180)"
          icon="mdi-cash"
          title="Rcebido"
          sub-icon-color="red"
          :valuex='false'
          :value='formatarMoeda(this.totalrecebido)'
          :sub-text="'Total Recebido'"
        />
    
    
      </v-col>
    
    
    
      </v-row>

      <br/>
     
      <v-divider></v-divider>
      
      <br/><br/>


    <base-material-card
    icon="mdi-handshake"
    title="Renegociações" style="margin-top:0px !important"
    color="rgb(71 130 180)"
    class="px-5 py-0"
  > 
      <v-row>
        <v-col cols="12">
  
          <v-data-table 
          :headers="this.headers_vendas"
          :items="lista_dessert"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :items-per-page-text="'Registros por página'" 
          class="elevation-0 no-hover-effect"
  
        >
  
        <template v-slot:top>
          <v-progress-linear
            v-if="isLoading" 
            indeterminate
            color="rgb(71 130 180)"
          ></v-progress-linear>
        </template>
  

        <template v-slot:item="row">
          <tr :class="{'cor-row': row.index % 2 !== 0}" >
           
           
            <td class="linha" style="width: 5%; font-size: 11px !important;font-weight: bold;font-family: Verdana, Geneva, Tahoma, sans-serif;">
              {{ row.item.contrato ? row.item.contrato : '-------' }}
            </td>
          
            <td  class="linha" style="width: 6%; ">{{ row.item.doc }}</td>
            <td class="linha" style="width: 34%; ">{{ row.item.produto }} </td>
            <td class="linha" style="width: 16%; ">{{row.item.empresa}} </td>

            <td class="linha" style="width: 8%; font-size: 14px;">
              {{
                parseFloat(row.item.valor).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })
              }}
            </td>
            <td class="linha" style="width: 8%; ">{{ row.item.dtEmissao }}</td>
            <td class="linha" style="width: 8%; ">{{ row.item.dtVencimento }}</td>

            <td class="linha" style="width: 8%; ">
              {{
                parseFloat(row.item.totalRecebido).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                })
              }}
            </td>

            <td class="linha" style="width: 10%; ">{{ row.item.status }}</td>


            
        </tr>
      </template> 
     
  
      <template v-slot:footer="{  }">
  
       
        <v-spacer></v-spacer> <br/>
       
  
  
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="6" style="background-dolor:blue">
            <!-- Paginação padrão -->
            <v-pagination v-model="currentPage" :length="totalPages" @input="changePage" :max="3"></v-pagination>
          </v-col>
        </v-row>  
       

      </template>
    
      
  
  
      </v-data-table>
  
     
      
        
        </v-col>  
       
      
    
  
      </v-row>
     
  
      <br/>
  
      <div class="d-flex justify-end" style="color:#FF8C00; font-size: 48px; margin-right: 12px;">
        <span @click="download_xls"  style="color:green; font-size: 48px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-file-excel"></i> 
            </span>      </div>
  
    
      </base-material-card>
  
  
    </v-container>
  
  </v-app> 
  </template>
  
  <script>
  import Contrato from '../../services/contrato'
  import FiltroComponent from "./components/FiltroGeral.vue"; // Certifique-se de que o caminho esteja correto

  
    export default {
    components: {
      FiltroComponent

    },
    
    filters: {
      formatDate(date) {
        if (!date) return '';
        const parsedDate = new Date(date);
        const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
        const year = parsedDate.getFullYear();
        return `${day}/${month}/${year}`;
      },
    },
  
  
   
      name: 'Contrato',
   
      data () {
        return {
  
          rules: {
            required: value => !!value || 'Obrigatório.',
          },
          currentPage: 1, // Página atual
          itemsPerPage: 10, // Itens por página
          pageNumber: 1, // Número da página
          totalPages:0,
          selectedStartDate: "",
          selectedEndDate: "",
          totalRecords:0,
          totalrecebido:0,
          totalEsperado:0,
          total_contrato:0,
          showProgress: false,
          showFilter: false,
  
          headers_vendas: [
            {
              text: 'Contrato',
              align: 'start',
              sortable: false,
              value: 'contrato',
            },
            { text: 'Doc', value: 'doc' ,   sortable: false, },
            { text: 'Produto', value: 'nomePlano' ,   sortable: false, },
            { text: 'Empresa', value: 'empresa' ,   sortable: false, },

            { text: 'Valor', value: 'valor' ,   sortable: false, },
            { text: 'Emissao', value: 'dtEmissao',   sortable: false, },
            { text: 'Vencimento', value: 'dtVencimento',   sortable: false, },
            { text: 'Recebido', value: 'totalRecebido' ,   sortable: false, },
            { text: 'Situcão do Acordo', value: 'status',   sortable: false, }
          
            
          ],
  
          lista_dessert: [],
  
          selectedStartDate : "",
          selectedEndDate : "",

          selectedStartDateR : "",
          selectedEndDateR : "",

          selectedStartDateE : "",
          selectedEndDateE : "",
          produto: 'Todos',

          empresa: 'Todas',
          selectedYear: "Todos",
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          selectMode: "Vencimento",
          selectedStartDate : "",
          selectedEndDate : "",
          opcoes: ["Todas", "Ativo", "Cancelado"],
          modos: ["Vencimento", "Emissão", "Pagamento"],
          isLoading: false,          
          tipos: ["Todos", "Planos", "Jazigo", "Cremação", "Funerária", "Serviços Administrativos", "Serviços Cemiteriais"],
          

          tipos_funerarios: [
                "Todos",
                "COMPLETO",
                "CONCESSÃO DE COLUMBÁRIO",
                "CONCESSÃO DE JAZIGOS",
                "CONCESSÃO DE OSSÁRIO",
                "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS",
                "URNAS CINERARIAS",
                "VENDA DE COLUMBÁRIO",
                "VENDA DE JAZIGOS - IMEDIATO",
                "VENDA DE JAZIGOS - PREVENTIVO",
                "VENDA DE JAZIGOS - TRANSITÓRIO"
              ],
  

        }

        
    },
  
    computed: {
      startIndex() {
        return (this.currentPage - 1) * this.itemsPerPage;
      },   
     
    },
  
    
    created() {
      // Defina a data atual como valor inicial para selectedStartDate
      this.loadPageData();
      const currentDate = new Date();
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const year = currentDate.getFullYear();
      this.selectedStartDate = `${"01"}/${month}/${year}`;
      this.selectedEndDate = `${day}/${month}/${year}`;
    },
  
  
      methods: {
  
        changePage() {
          this.isLoading = true;
          this.loadPageData();
          this.fetchData(this.currentPage);
  
       },
  
       loadPageData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      },
      
      

       
      filterData(valores) {

        this.isLoading = true;
        this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
        this.selectedOpcao = valores.selectedOpcao;
        this.selectedCompany = valores.selectedCompany.join(",");
        this.selectMode = valores.selectMode;
        this.selectedStartDate = valores.selectedStartDate;
        this.selectedEndDate = valores.selectedEndDate;
        this.produto = valores.selectedProduto;

        this.selectedStartDateR = valores.selectedStartDate2;
        this.selectedEndDateR = valores.selectedEndDate2;
        this.selectedStartDateE = valores.selectedStartDate3;
        this.selectedEndDateE = valores.selectedEndDate3;

        this.currentPage = 1, // Página atual
        this.totalRecords = 0;
        this.fetchData(1);

        },
          
        formatarMoeda(valor) {
          // Verifica se o valor é numérico
          if (isNaN(valor)) {
              return 'Valor inválido';
          }

          // Formata o valor como moeda brasileira (BRL)
          return valor.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
          });
         },
  
          download_xls() {
             
              
              this.showProgress = true;

              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              const formattedStartDateR = converteDataParaFormatoISO(this.selectedStartDateR);
              const formattedEndDateR = converteDataParaFormatoISO(this.selectedEndDateR);
             

              const formattedStartDateE = converteDataParaFormatoISO(this.selectedStartDateE);
              const formattedEndDateE = converteDataParaFormatoISO(this.selectedEndDateE);
             
              
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);
              const diferencaEmDiasE = this.calcularDiferencaEntreDatas(formattedStartDateE, formattedEndDateE);
              const diferencaEmDiasR = this.calcularDiferencaEntreDatas(formattedStartDateR, formattedEndDateR);


              if (diferencaEmDiasE > 365 || diferencaEmDiasR > 365 || diferencaEmDias > 365) {
                this.isLoading = false;
                this.showFilter = true;
                this.dias = 365;
                return 
              }
              


              Contrato.lista_acordos_xls(formattedStartDate, formattedEndDate, formattedStartDateR, formattedEndDateR, formattedStartDateE, formattedEndDateE, this.selectedCompany, this.selectedType,  this.selectMode, this.selectedOpcao, this.produto)
              .then(response => {
                        if (response.status === 200) {
                         
                                const fileName = `acordos_${Date.now()}.xlsx`;
                                const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                                const fileLink = document.createElement('a')
                                fileLink.href = fileURL
                                fileLink.setAttribute('download', fileName)
                                document.body.appendChild(fileLink)
                                fileLink.click()
                             
                        }
                      })
                      .catch(error => {
                        // Lida com erros
                        console.error('Erro ao baixar o arquivo Excel:', error);
                      }) .finally(() => {
                        // Após o término do download, oculte o diálogo de progresso
                        this.showProgress = false;
                      });
            },
          
            calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
            },

          

          fetchData(pageNumber) {
            if(this.selectMode == 'Vencimento' ){
                
                this.selectedStartDateR = '';
                this.selectedEndDateR = '';
                this.selectedStartDateE = '';
                this.selectedEndDateE = '';

              }else

              if(this.selectMode == 'Pagamento' ){
                this.selectedStartDate = '';
                this.selectedEndDate = '';
                this.selectedStartDateE = '';
                this.selectedEndDateE = '';
              } else

              if(this.selectMode == 'Emissão' ){
                
                this.selectedStartDate = '';
                this.selectedEndDate = '';
                this.selectedStartDateR = '';
                this.selectedEndDateR = '';
              }
             
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              const formattedStartDateR = converteDataParaFormatoISO(this.selectedStartDateR);
              const formattedEndDateR = converteDataParaFormatoISO(this.selectedEndDateR);
             

              const formattedStartDateE = converteDataParaFormatoISO(this.selectedStartDateE);
              const formattedEndDateE = converteDataParaFormatoISO(this.selectedEndDateE);
             
              
              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);
              const diferencaEmDiasE = this.calcularDiferencaEntreDatas(formattedStartDateE, formattedEndDateE);
              const diferencaEmDiasR = this.calcularDiferencaEntreDatas(formattedStartDateR, formattedEndDateR);


              if (diferencaEmDiasE > 365 || diferencaEmDiasR > 365 || diferencaEmDias > 365) {
                this.isLoading = false;
                this.showFilter = true;
                this.dias = 365;
                return 
              }

              Contrato.lista_acordos(formattedStartDate, formattedEndDate, formattedStartDateR, formattedEndDateR, formattedStartDateE, formattedEndDateE, this.selectedCompany, this.selectedType, this.selectMode, this.selectedOpcao, this.produto, pageNumber, this.itemsPerPage)
                .then(response => {
                  if (response.status === 200) {
                    this.lista_dessert = response.data.content;
                    this.totalPages = response.data.totalPages; // Total de páginas disponíveis
                    this.loading = false; // Esconder o loading após a pesquisa
                    // Atualize o número da página atual ou outras variáveis de controle, se necessário
                    this.totalRecords = response.data.totalElements; // Substitua totalRecordsFromApi pelo valor real
                    this.total_contrato = response.data.total_contrato; // Substitua totalRecordsFromApi pelo valor real
                    this.totalEsperado = response.data.totalEsperado; // Substitua totalRecordsFromApi pelo valor real
                    this.totalrecebido = response.data.totalrecebido; // Substitua totalRecordsFromApi pelo valor real

                  }
                })
                .catch(e => {
                  // Lida com erros
                  this.isLoading = false;
                  this.totalRecords = 0;
                  this.totalrecebido = 0;
                  this.totalEsperado = 0;
                  this.total_contrato = 0;
                })
                .finally(() => {
                  this.isLoading = false;
                });
            }
      }  
    }
  
    function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }
  
  
  </script>
  
  
  <style scoped>
    .v-progress-circular {
      margin: 1rem;
    }
  
    .custom-gray-background {
      height: 80px;
    }
    
    .row {
      display: flex;
      flex-wrap: wrap;
       flex: 0 1 auto !important; 
      margin: -12px;
  }
  
  
    /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
    .fill-width {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      display: flex;
    }



.linha{

    font-size: 11px !important;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .no-hover-effect.v-data-table tbody tr:hover {
    color: #3055a4;
  }

    
    /* Centralize horizontalmente */
    .justify-center {
      justify-content: center;
    }
    


    .gray-row {
  background-color: #e7f1ff; /* ou qualquer outra cor cinza que você preferir */
}
.cor-row {
  background-color: rgb(231 244 255)   ;
  transition: none !important; /* Desativa a transição de cor */

}
    
    </style>
  